import { useState } from "react";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
// import { useControlPermission } from "../../utils/ActionPermissionUtils";
import Pagination from "../../components/Pagination";
import { usePartnerSplitterListHooks } from "../../utils/infrastructure/PartnerSplitterUtils";

export default function ODPListPage() {
  // const { hasAccess } = useControlPermission();
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  let [filter, setFilter] = useState({ q: "", page: 1, page_size: 20 });

  const { data: splitterData, meta, loading, errorMsg } = usePartnerSplitterListHooks({ filter });

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  return (
    <MainLayout resource_key="partner-infrastructure">
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col">
            <h1 className="h3 mb-0 text-gray-800">Daftar ODP Splitter</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Box title={`ODP Splitter Partner (Total: ${meta.count})`} loading={loading}>
              {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
              <AlertBox {...alert} setAlert={setAlert} />
              <div className="auto-horizontal-scroll">
                <table className="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <th className="text-nowrap">Splitter ID</th>
                      <th className="text-nowrap">OLT ID</th>
                      <th className="text-nowrap">Jumlah Port</th>
                      <th className="text-nowrap">Latitude</th>
                      <th className="text-nowrap">Longitude</th>
                      <th className="text-nowrap">Partner</th>
                    </tr>
                  </thead>
                  <tbody>
                    {splitterData.length === 0 ? (
                      <tr>
                        <td colSpan={6}>Tidak ada data</td>
                      </tr>
                    ) : null}
                    {splitterData.map((splitter) => (
                      <tr key={splitter.id}>
                        <td>{splitter.partner_odp_splitter_id}</td>
                        <td>{splitter.partner_olt_id}</td>
                        <td>{splitter.number_of_ports}</td>
                        <td>{splitter.latitude}</td>
                        <td>{splitter.longitude}</td>
                        <td>
                          {splitter.partner.name} ({splitter.partner?.code})
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {meta.count > meta.page_size && (
                  <Pagination
                    totalpage={Math.ceil(meta.count / meta.page_size)}
                    selectedpage={meta.page}
                    clickpage={(page) => gotoPage(page)}
                    next={() => gotoPage(meta.page + 1)}
                    prev={() => gotoPage(meta.page - 1)}
                  />
                )}
              </div>
            </Box>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
