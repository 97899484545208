import { useState, useEffect } from "react";
import { sendReportInstallation } from "../api/user";
import Box from "../components/Box";
import AlertBox from "../components/AlertBox";
import MainLayout from "../components/layouts/MainLayout";
import ImageUploader from "../components/ImageUploader";
import { DataRegistrationBoxes } from "../components/registration/DataRegistrationBoxes";
import ConfidentialImageUploader from "../components/ConfidentialImageUploader";
import DropdownSelectHelper from "../components/technician/DropdownSelectHelper";
import { Modal } from "react-bootstrap";
import { convertToRupiah } from "../tools/numerictools";
import { useSurveyDefaultItems } from "../utils/SettingUtils";
import { useBastScheduleListHooks, useScheduleDetailHooks } from "../utils/SchedulesUtil";
import { bastSchedule } from "../api/schedules";
import ResponsiveImageUploader from "../components/ResponsiveImageUploader";
import { ADDITIONAL_DOCS } from "../tools/BastDictionary";

export default function ReportBastPage() {
  let [number, setNumber] = useState("");
  let [filter, setFilter] = useState({ type: "installation" });
  let { schedules } = useBastScheduleListHooks({ filter });

  let [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [alertModem, setAlertModem] = useState({ show: false, message: "", type: "" });

  let {
    data: registrationData,
    fetched: registrationDataFetched,
    loading: registrationDataLoading,
    error: registrationDataError,
    resetData: resetRegistrationData,
  } = useScheduleDetailHooks({ id: number });

  useEffect(() => {
    if (registrationData.error) {
      setAlert({ show: true, message: registrationData.error, type: "danger" });
    }
  }, [registrationData.error]);

  const onChangeRegisNumber = (value) => {
    setNumber(value);
  };

  const onSuccess = (message) => {
    setAlert({
      show: true,
      message: message + " - Berhasil melakukan pelaporan",
      type: "success",
    });
    resetData();
  };

  const resetData = () => {
    // reset form
    setNumber("");

    // refetch data
    setFilter({ type: "installation" });
    resetRegistrationData();
  };

  return (
    <MainLayout>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Report Survey dan BAST Instalasi</h1>
        </div>
        <form>
          <div className="row">
            <div className="col-auto">
              <div className="form-group">
                <label htmlFor="registration_number">Nomor Registrasi</label>
                <select
                  className="form-control"
                  value={number}
                  onChange={(event) => onChangeRegisNumber(event.target.value)}
                >
                  <option value="" disabled>
                    Pilih Nomor Registrasi
                  </option>
                  {schedules.map((schedule, index) => (
                    <option value={schedule.id} key={index}>
                      {schedule.registration_number}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </form>

        <div className="row">
          <div className="col">
            {registrationDataError && (
              <div className="alert alert-danger">{registrationDataError}</div>
            )}
            <AlertBox {...alert} setAlert={setAlert} />
            <AlertBox {...alertModem} setAlert={setAlertModem} />
          </div>
        </div>

        {registrationDataFetched ? (
          <div className="row">
            <div className="col-12 col-lg-4">
              <DataRegistrationBoxes data={registrationData} type="INSTALLATION" />
            </div>
            <div className="col-12 col-lg-6">
              {registrationData.survey === true ? (
                <FormSurveyReport
                  id={registrationData.id}
                  registration={registrationData}
                  onSuccess={onSuccess}
                />
              ) : (
                <FormBastInstall
                  id={registrationData.registration.id}
                  data={registrationData}
                  modem={registrationData.registration.inventory_modem_pair}
                  onSuccess={onSuccess}
                />
              )}
            </div>
          </div>
        ) : registrationDataLoading ? (
          <div className="alert alert-warning">Loading ...</div>
        ) : (
          <div className="card mb-4 border-left-info">
            <div className="card-body">
              Masukan <b>nomor registrasi</b> terlebih dahulu
            </div>
          </div>
        )}
      </div>
    </MainLayout>
  );
}

function FormBastInstall({ id, modem, data, onSuccess }) {
  let [alert, setAlert] = useState({ show: false, message: "", type: "" });

  let [helper, setHelper] = useState(null);

  let [form, setForm] = useState({
    ont_brand: "",
    note: "",
    material_note: "",
    ont_number: "",
    serial_number_gpon: "",
    pppoe_username: "",
    pppoe_password: "",
    odp_number: "",
    olt_number: "",
    odc_number: "",
    username: "",
    password: "",
    id_image_url: "",
    modem_image_url: "",
    pillar_image_url: "",
    bast_image_url: "",
    speed_test_image_url: "",
    k3_pre_install_image_url: "",
    k3_post_install_image_url: "",
    latitude: "",
    longitude: "",
  });

  let [additionalDocs, setAdditionalDocs] = useState({
    "k3-pre-installation": "",
    "k3-post-installation": "",
    "k3-fat-initial-condition": "",
    "k3-fat-box-before": "",
    "k3-fat-box-after": "",
    "k3-fat-in-damping-check": "",
    "k3-fat-out-damping-check": "",
    "k3-fat-splitter-port-status": "",
    "k3-fat-spare-cable-arrangement": "",
  });

  const onChangeImg = (type, image_url) => {
    setForm({ ...form, [type]: image_url });
  };

  const onChangeAdditionalImg = (type, image_url) => {
    setAdditionalDocs({ ...additionalDocs, [type]: image_url });
  };

  const onSubmitData = (event) => {
    event.preventDefault();

    let formData = {
      id_image_url: form.id_image_url,
      modem_image_url: form.modem_image_url,
      pillar_image_url: form.pillar_image_url,
      bast_image_url: form.bast_image_url,
      speed_test_image_url: form.speed_test_image_url,
      note: form.note,
      material_note: form.material_note,
      helper_id: helper?.value || "",
      latitude: form.latitude,
      longitude: form.longitude,
      additional_account: {
        username: form.username,
        password: form.password,
      },
    };

    if (data.registration.project?.partner?.code === "ICONNET") {
      formData.attachments = Object.entries(additionalDocs).map(([type, value]) => ({
        type,
        url: value,
      }));
    }

    sendReport(formData);
  };

  const sendReport = async (form_data) => {
    try {
      let { data } = await sendReportInstallation(id, form_data);
      onSuccess(data.message);
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message: message, type: "danger" });
      topFunction();
    }
  };

  const topFunction = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  return (
    <Box title="Form Laporan BAST">
      <form onSubmit={(event) => onSubmitData(event)}>
        <AlertBox {...alert} setAlert={setAlert} />
        <div className="row">
          <div className="col-12 pb-2">
            <h4>Informasi Perangkat</h4>
          </div>
          <div className="col-12">
            {modem === null && (
              <div className="alert alert-danger">
                <b>
                  <i className="fa fa-triangle-exclamation"></i> Perhatian!
                </b>{" "}
                Silahkan setup modem terlebih dahulu
              </div>
            )}
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th colSpan={2}>Informasi Setup Modem</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ width: "150px" }}>Merk ONT</td>
                  <td>{modem?.modem?.brand || "-"}</td>
                </tr>
                <tr>
                  <td>Nomor Seri ONT</td>
                  <td>{modem?.modem?.serial_number_modem || "-"}</td>
                </tr>
                <tr>
                  <td>Nomor Seri GPON</td>
                  <td>{modem?.modem?.serial_number_gpon || "-"}</td>
                </tr>
                <tr>
                  <td>PPPoE Username</td>
                  <td>{modem?.modem?.pppoe_username || "-"}</td>
                </tr>
                <tr>
                  <td>PPPoE Password</td>
                  <td>{modem?.modem?.pppoe_password || "-"}</td>
                </tr>
                <tr>
                  <td>OLT</td>
                  <td>{modem?.modem?.olt || "-"}</td>
                </tr>
                <tr>
                  <td>ODC</td>
                  <td>{modem?.modem?.odc || "-"}</td>
                </tr>
                <tr>
                  <td>ODP</td>
                  <td>{modem?.modem?.odp || "-"}</td>
                </tr>
                {data.registration.project?.partner?.code === "ICONNET" ? (
                  <>
                    <tr>
                      <td>Splitter ID</td>
                      <td>{modem?.modem?.partner_odp_splitter_id || "-"}</td>
                    </tr>
                    <tr>
                      <td>Splitter Port</td>
                      <td>{modem?.modem?.partner_odp_splitter_port || "-"}</td>
                    </tr>
                    <tr>
                      <td>MAC Address ONT/Modem</td>
                      <td>{modem?.modem?.mac_address || "-"}</td>
                    </tr>
                    <tr>
                      <td>Latitude</td>
                      <td>{modem?.modem?.latitude || "-"}</td>
                    </tr>
                    <tr>
                      <td>Longitude</td>
                      <td>{modem?.modem?.longitude || "-"}</td>
                    </tr>
                  </>
                ) : null}
              </tbody>
            </table>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Username/SSID</label>
              <input
                type="text"
                className="form-control"
                required
                value={form.username}
                onChange={(event) => setForm({ ...form, username: event.target.value })}
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="text"
                className="form-control"
                required
                value={form.password}
                onChange={(event) => setForm({ ...form, password: event.target.value })}
              />
            </div>
            <div className="form-group">
              <label>Catatan</label>
              <textarea
                rows="5"
                className="form-control"
                value={form.note}
                onChange={(event) => setForm({ ...form, note: event.target.value })}
              ></textarea>
            </div>
            <div className="form-group">
              <label>Catatan Material</label>
              <textarea
                rows="5"
                className="form-control"
                value={form.material_note}
                onChange={(event) => setForm({ ...form, material_note: event.target.value })}
              ></textarea>
            </div>
            <div className="form-group">
              <label>Helper</label>
              <DropdownSelectHelper
                value={helper}
                onChangeValue={(selected) => setHelper(selected)}
              />
            </div>
            <div className="alert alert-warning">
              <i className="fa fa-triangle-exclamation"></i> <b>Pastikan lokasi pelanggan benar</b>
            </div>
            <div className="form-group">
              <label>Latitude</label>
              <input
                type="text"
                className="form-control"
                value={form.latitude}
                onChange={(event) => setForm({ ...form, latitude: event.target.value })}
              />
            </div>
            <div className="form-group">
              <label>Longitude</label>
              <input
                type="text"
                className="form-control"
                value={form.longitude}
                onChange={(event) => setForm({ ...form, longitude: event.target.value })}
              />
            </div>
          </div>
          {/* Form K3 */}
          {data.registration.project?.partner?.code === "ICONNET" ? (
            <div className="col-12 py-4">
              <h4>Dokumen K3</h4>
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group mb-4">
                    <label>{ADDITIONAL_DOCS["k3-pre-installation"]}</label>
                    <ResponsiveImageUploader
                      image={additionalDocs["k3-pre-installation"]}
                      type="k3-pre-installation"
                      onChangeImg={onChangeAdditionalImg}
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group mb-4">
                    <label>{ADDITIONAL_DOCS["k3-post-installation"]}</label>
                    <ResponsiveImageUploader
                      image={additionalDocs["k3-post-installation"]}
                      type="k3-post-installation"
                      onChangeImg={onChangeAdditionalImg}
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group mb-4">
                    <label>{ADDITIONAL_DOCS["k3-fat-initial-condition"]}</label>
                    <ResponsiveImageUploader
                      image={additionalDocs["k3-fat-initial-condition"]}
                      type="k3-fat-initial-condition"
                      onChangeImg={onChangeAdditionalImg}
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group mb-4">
                    <label>{ADDITIONAL_DOCS["k3-fat-box-before"]}</label>
                    <ResponsiveImageUploader
                      image={additionalDocs["k3-fat-box-before"]}
                      type="k3-fat-box-before"
                      onChangeImg={onChangeAdditionalImg}
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group mb-4">
                    <label>{ADDITIONAL_DOCS["k3-fat-box-after"]}</label>
                    <ResponsiveImageUploader
                      image={additionalDocs["k3-fat-box-after"]}
                      type="k3-fat-box-after"
                      onChangeImg={onChangeAdditionalImg}
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group mb-4">
                    <label>{ADDITIONAL_DOCS["k3-fat-in-damping-check"]}</label>
                    <ResponsiveImageUploader
                      image={additionalDocs["k3-fat-in-damping-check"]}
                      type="k3-fat-in-damping-check"
                      onChangeImg={onChangeAdditionalImg}
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group mb-4">
                    <label>{ADDITIONAL_DOCS["k3-fat-out-damping-check"]}</label>
                    <ResponsiveImageUploader
                      image={additionalDocs["k3-fat-out-damping-check"]}
                      type="k3-fat-out-damping-check"
                      onChangeImg={onChangeAdditionalImg}
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group mb-4">
                    <label>{ADDITIONAL_DOCS["k3-fat-splitter-port-status"]}</label>
                    <ResponsiveImageUploader
                      image={additionalDocs["k3-fat-splitter-port-status"]}
                      type="k3-fat-splitter-port-status"
                      onChangeImg={onChangeAdditionalImg}
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group mb-4">
                    <label>{ADDITIONAL_DOCS["k3-fat-spare-cable-arrangement"]}</label>
                    <ResponsiveImageUploader
                      image={additionalDocs["k3-fat-spare-cable-arrangement"]}
                      type="k3-fat-spare-cable-arrangement"
                      onChangeImg={onChangeAdditionalImg}
                      required={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="col-12 py-4">
            <h4>Dokumen Pendukung</h4>
          </div>
          <div className="col-md-12">
            <div className="form-group mb-4">
              <label>Foto KTP Pelangan</label>
              <ConfidentialImageUploader
                image={form.id_image_url}
                type="id_image_url"
                onChangeImg={onChangeImg}
                required={true}
              />
            </div>
            <div className="form-group mb-4">
              <label>Foto Lokasi Modem</label>
              <ImageUploader
                image={form.modem_image_url}
                type="modem_image_url"
                onChangeImg={onChangeImg}
                required={true}
              />
            </div>
            <div className="form-group mb-4">
              <label>Foto Tiang Jaringan</label>
              <ImageUploader
                image={form.pillar_image_url}
                type="pillar_image_url"
                onChangeImg={onChangeImg}
                required={true}
              />
            </div>
            <div className="form-group mb-4">
              <label>Hasil Uji Kecepatan Internet</label>
              <ImageUploader
                image={form.speed_test_image_url}
                type="speed_test_image_url"
                onChangeImg={onChangeImg}
                required={true}
              />
            </div>
            <div className="form-group mb-4">
              <label>Tanda Tangan Pelanggan</label>
              <ImageUploader
                image={form.bast_image_url}
                type="bast_image_url"
                onChangeImg={onChangeImg}
                required={true}
              />
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <button className="btn btn-primary">Simpan Laporan BAST</button>
        </div>
      </form>
    </Box>
  );
}

function FormSurveyReport({ id, onSuccess, registration }) {
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [loading, setLoading] = useState(false);
  const [installable, setInstallable] = useState("1");
  const [addAdditional, setAddAdditional] = useState("0");
  const [agreedAdditional, setAgreedAdditional] = useState("1");
  const [additionalItems, setAdditionalItems] = useState([]);

  const [form, setForm] = useState({
    latitude: "",
    longitude: "",
    additional_item: "false",
    installation_agreement: "true",
    signature_image_url: "",
    signature_description: "",
    note: "",
  });
  const [modal, setModal] = useState(null);

  const addAdditionalItems = (value) => {
    const tempAdditional = [...additionalItems];
    tempAdditional.push({ ...value, qty: 1 });
    setAdditionalItems([...tempAdditional]);
  };

  const changeQtyAdditionalItems = (value, index) => {
    const tempAdditional = [...additionalItems];
    tempAdditional[index].qty = value;
    setAdditionalItems([...tempAdditional]);
  };

  const removeAdditionalItems = (index) => {
    const tempAdditional = [...additionalItems];
    tempAdditional.splice(index, 1);
    setAdditionalItems([...tempAdditional]);
  };

  const onChangeImg = (type, image_url) => {
    setForm({ ...form, [type]: image_url });
  };

  const submitData = (event) => {
    event.preventDefault();

    let formData = {
      installable: installable === "1",
      latitude: form.latitude || "",
      longitude: form.longitude || "",
    };

    if (formData.installable) {
      formData.additional_item = addAdditional === "1";
      if (formData.additional_item) {
        formData.additional_items = additionalItems.map((item) => ({
          name: item.name,
          qty: item.qty,
        }));
        formData.installation_agreement = agreedAdditional === "1";
        if (agreedAdditional === "1") {
          formData.attachments = [
            {
              url: form.signature_image_url,
              type: "signature",
              description: form.signature_description,
            },
          ];
        } else {
          formData.note = form.note;
        }
      }
    } else {
      formData.note = form.note;
    }
    sendReport(formData);
  };

  const sendReport = async (form_data) => {
    setLoading(true);
    try {
      let { data } = await bastSchedule(id, form_data);
      onSuccess(data.message);
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message: message, type: "danger" });
      topFunction();
    }
    setLoading(false);
  };

  const topFunction = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  return (
    <Box title="Form Berita Acara Survey">
      <AlertBox {...alert} setAlert={setAlert} />
      <form onSubmit={(event) => submitData(event)}>
        {registration.registration.project?.partner?.code === "ICONNET" ? (
          <div className="form-group">
            <label className="font-weight-bold">Posisi Rumah Pelanggan</label>
            <div className="row">
              <div className="col-12 col-sm-6">
                <label>Latitude</label>
                <input
                  type="number"
                  value={form.latitude}
                  className="form-control"
                  onChange={(event) => setForm({ ...form, latitude: event.target.value })}
                  name="latitude"
                  required
                />
              </div>
              <div className="col-12 col-sm-6">
                <label>Longitude</label>
                <input
                  type="number"
                  value={form.longitude}
                  className="form-control"
                  onChange={(event) => setForm({ ...form, longitude: event.target.value })}
                  name="longitude"
                  required
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className="form-group">
          <label className="font-weight-bold">Lokasi dapat dilakukan pemasangan?</label>
          <div className="row">
            <div className="col-auto">
              <label>
                <input
                  type="radio"
                  value="1"
                  checked={installable === "1"}
                  onChange={(event) => setInstallable(event.target.value)}
                  name="coverage_booelan"
                />{" "}
                Bisa
              </label>
            </div>
            <div className="col-auto">
              <label>
                <input
                  type="radio"
                  value="0"
                  checked={installable === "0"}
                  onChange={(event) => setInstallable(event.target.value)}
                  name="coverage_booelan"
                />{" "}
                Tidak
              </label>
            </div>
          </div>
        </div>
        {installable === "0" && (
          <div className="form-group">
            <label className="font-weight-bold">Alasan tidak bisa dilakukan pemasangan</label>
            <textarea
              rows="4"
              className="form-control"
              value={form.note}
              onChange={(event) => setForm({ ...form, note: event.target.value })}
              required
            ></textarea>
          </div>
        )}

        {installable === "1" && (
          <div className="form-group">
            <div className="alert alert-warning">
              <b>Perhatian</b> Untuk pendaftaran status bangunan Fasilitas Umum tidak perlu
              melakukan penambahan item
            </div>
            <label className="font-weight-bold">Butuh tambahan item?</label>
            <div className="row">
              <div className="col-auto">
                <label>
                  <input
                    type="radio"
                    value="1"
                    checked={addAdditional === "1"}
                    onChange={(event) => setAddAdditional(event.target.value)}
                    name="additional_boolean"
                  />{" "}
                  Ya
                </label>
              </div>
              <div className="col-auto">
                <label>
                  <input
                    type="radio"
                    value="0"
                    checked={addAdditional === "0"}
                    onChange={(event) => setAddAdditional(event.target.value)}
                    name="additional_boolean"
                  />{" "}
                  Tidak
                </label>
              </div>
            </div>
          </div>
        )}
        {addAdditional === "1" && installable === "1" && (
          <>
            <div className="form-group">
              <div className="row mb-2">
                <div className="col">
                  <label className="font-weight-bold">Tambahan barang</label>
                </div>
                <div className="col-auto">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={() => setModal("add-item")}
                  >
                    <i className="fa fa-plus"></i> Tambah Item
                  </button>
                </div>
              </div>
              <table className="table table-bordered">
                <tbody>
                  {additionalItems.length === 0 && (
                    <tr>
                      <td colSpan={3} className="text-center">
                        Belum ada tambahan
                      </td>
                    </tr>
                  )}
                  {additionalItems.map((item, index) => (
                    <tr key={index}>
                      <td>
                        {item.name} <br /> (harga Rp. {convertToRupiah(item.price)} / {item.unit})
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          min={1}
                          value={item.qty}
                          onChange={(event) =>
                            changeQtyAdditionalItems(event.target.valueAsNumber, index)
                          }
                        />
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-sm btn-danger"
                          onClick={() => removeAdditionalItems(index)}
                        >
                          <i className="fa fa-times"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="form-group">
              <p className="font-weight-bold">Billing yang akan terbit</p>
              <BillingPreviewTable
                internet_package={registration.registration.internet_package}
                additional_items={additionalItems}
              />
            </div>
            <div className="form-group">
              <label className="font-weight-bold">Setuju dengan penambahan barang?</label>
              <div className="row">
                <div className="col-auto">
                  <label>
                    <input
                      type="radio"
                      value="1"
                      checked={agreedAdditional === "1"}
                      onChange={(event) => setAgreedAdditional(event.target.value)}
                      name="agreed_additional"
                    />{" "}
                    Ya
                  </label>
                </div>
                <div className="col-auto">
                  <label>
                    <input
                      type="radio"
                      value="0"
                      checked={agreedAdditional === "0"}
                      onChange={(event) => setAgreedAdditional(event.target.value)}
                      name="agreed_additional"
                    />{" "}
                    Tidak
                  </label>
                </div>
              </div>
            </div>
            {agreedAdditional === "0" ? (
              <div className="form-group">
                <label className="font-weight-bold">Alasan Penolakan</label>
                <textarea
                  rows="4"
                  className="form-control"
                  value={form.note}
                  onChange={(event) => setForm({ ...form, note: event.target.value })}
                  required
                ></textarea>
              </div>
            ) : (
              <>
                <div className="form-group">
                  <label className="font-weight-bold">Tanda Tangan</label>
                  <ImageUploader
                    image={form.signature_image_url}
                    type="signature_image_url"
                    onChangeImg={onChangeImg}
                    required={true}
                  />
                </div>
                <div className="form-group">
                  <label className="font-weight-bold">Nama Terang</label>
                  <input
                    type="text"
                    className="form-control"
                    value={form.signature_description}
                    onChange={(event) =>
                      setForm({ ...form, signature_description: event.target.value })
                    }
                  />
                </div>
              </>
            )}
          </>
        )}
        <div className="form-group d-flex justify-content-end">
          <button className="btn btn-primary" disabled={loading}>
            {loading ? "Menyimpan ..." : "Simpan Hasil Survey"}
          </button>
        </div>
      </form>

      <ModalAddItemSurvey
        show={modal === "add-item"}
        addItem={(value) => addAdditionalItems(value)}
        onClose={() => setModal(null)}
      />
    </Box>
  );
}

function ModalAddItemSurvey({ show, onClose, addItem }) {
  const { data } = useSurveyDefaultItems();

  const handleClose = () => {
    onClose();
  };

  const pushItem = (item) => {
    addItem(item);
    handleClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Tambahan Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th>Item</th>
              <th>Unit</th>
              <th>Harga per unit</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>Rp. {convertToRupiah(item.price)}</td>
                <td>{item.unit}</td>
                <td>
                  <button className="btn btn-sm btn-primary" onClick={() => pushItem(item)}>
                    <i className="fa fa-plus"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
}

function BillingPreviewTable({ internet_package, additional_items }) {
  const totalCost = () => {
    let price = 0;
    price =
      internet_package.price + internet_package.installation_cost + internet_package.deposit_amount;

    additional_items.forEach((addItem) => {
      price += addItem.price * addItem.qty;
    });

    return price;
  };

  return (
    <table className="table table-bordered table-striped">
      <thead>
        <tr>
          <th>Item</th>
          <th>Harga</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{internet_package?.name}</td>
          <td>Rp. {convertToRupiah(internet_package?.price || 0)}</td>
        </tr>
        <tr>
          <td>Biaya Instalasi</td>
          <td>Rp. {convertToRupiah(internet_package?.installation_cost || 0)}</td>
        </tr>
        <tr>
          <td>Deposit</td>
          <td>Rp. {convertToRupiah(internet_package?.deposit_amount || 0)}</td>
        </tr>
        <tr>
          <td colSpan={2} className="font-weight-bold">
            Item Tambahan
          </td>
        </tr>
        {additional_items.length === 0 && (
          <tr>
            <td colSpan={2} className="text-sm text-center">
              Belum ada item tambahan
            </td>
          </tr>
        )}
        {additional_items.map((item, index) => (
          <tr key={index}>
            <td>
              {item.name} ({item.qty} {item.unit})
            </td>
            <td>Rp. {convertToRupiah(item.qty * item.price)}</td>
          </tr>
        ))}
        <tr>
          <td>
            <b>Total</b>
          </td>
          <td>Rp. {convertToRupiah(totalCost())}</td>
        </tr>
      </tbody>
    </table>
  );
}
